import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="coming-soon-link"
export default class extends Controller {
  static values = { message: String }
  static targets = ["link"];

  connect() {
     this.links = document.querySelectorAll(".coming-soon-link");

    this.links.forEach((link) => {
      link.addEventListener("mouseenter", this.onMouseEnter.bind(this));
      link.addEventListener("mouseleave", this.onMouseLeave.bind(this));
      link.dataset.originalText = link.innerHTML;
    });
  }

  onMouseEnter(event) {
    event.currentTarget.innerHTML = this.messageValue;
  }

  onMouseLeave(event) {
    event.currentTarget.innerHTML = event.currentTarget.dataset.originalText;
  }
}
