import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters-mobile"
export default class extends Controller {
  static targets = ["filterDetails", "filtersForm", "filtersState"]

  connect() {
    this.filterDetailsTarget.addEventListener("click", this.toggleFilters.bind(this))
  }

  toggleFilters() {
    const isHidden = this.filtersFormTarget.classList.toggle("hide-filters")
    this.filtersFormTarget.classList.toggle("show-filters", !isHidden)
    this.filtersStateTarget.innerHTML = isHidden ? this.filtersStateTarget.dataset.show : this.filtersStateTarget.dataset.hide
  }
}
