import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mela-nav"
export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: 0.5
    });

    const challengeSection = document.getElementById("challenge");
    if (challengeSection) {
      this.observer.observe(challengeSection);
    }

    this.activateLink("home");
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
        this.activateLink("challenge");
      } else {
        this.deactivateLink("challenge");
      }
    });
  }

  activateLink(section) {
    this.linkTargets.forEach(link => {
      if (link.getAttribute("href").includes(`#${section}`)) {
        link.classList.add("active");
      } else if (section === "challenge" && link.getAttribute("href").includes("#home")) {
        link.classList.remove("active");
      }
    });
  }

  deactivateLink(section) {
    this.linkTargets.forEach(link => {
      if (link.getAttribute("href").includes(`#${section}`)) {
        link.classList.remove("active");
      } else if (section === "challenge" && link.getAttribute("href").includes("#home")) {
        link.classList.add("active");
      }
    });
  }
}
