import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hover-image"
export default class extends Controller {
  static targets = ["image"];

  connect() {
    this.defaultImage = this.imageTarget.dataset.defaultImage;
    this.hoverImage = this.imageTarget.dataset.hoverImage;
  }

  changeImage() {
    this.setImage(this.hoverImage);
  }

  restoreImage() {
    this.setImage(this.defaultImage);
  }

  setImage(src) {
    this.imageTarget.dataset.transitioning = "true";
    this.imageTarget.src = src;
    this.imageTarget.style.opacity = "0";
    this.imageTarget.addEventListener('load', () => {
      this.imageTarget.style.opacity = "1";
      this.imageTarget.dataset.transitioning = "false";
    });
  }
}
