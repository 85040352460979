import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="plugins--file"
export default class extends Controller {
  static targets = ["resource"]

  resourceTargetConnected(resource) {
    resource.addEventListener('click', (event) => {
      document.querySelectorAll('.btn.next-action').forEach(button => button.classList.remove('d-none'));
      document.querySelector('.btn.wait-action').forEach(button => button.classList.add('d-none'));
    });
  }
}
