import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="resource-form"
export default class extends Controller {

  static values = {
    url: String,
  }

  static targets = [
      "sourceValueInput"
  ]

  connect() {
    this.sourceValueInput = this.sourceValueInputTarget;
    this.url = this.urlValue;
  }

  async changeSource(event) {
    const selected = event.target.selectedOptions[0].value;
    const sourceUrl = this.url + '/' + selected;
    const response = await get(sourceUrl, {
      responseKind: 'json'
    });

    if (response.ok) {
      const json = await response.json;
      if (json.success) {
        const data = json.data;
        this.sourceValueInput.classList.remove('hidden');
        const description = data.description;
        if (data.selector === 'text') {
          this.sourceValueInput.innerHTML =
            `<label id="label-content" class="form-label" for="admin_panel_create_htmlForource_form_source_value">${description}</label>
            <input type="text" class="form-control" name="admin_panel_create_resource_form[source_value]" id="admin_panel_create_resource_form_source_value">
            <div id='source_value-error' class="text-danger"></div>`;
        }

        else if (data.selector === 'file' || 'image') {
          this.sourceValueInput.innerHTML =
              `<label id="label-content" class="form-label" for="admin_panel_create_htmlForource_form_source_value">${description}</label>
               <input type="file" class="form-control" name="admin_panel_create_resource_form[file]" id="admin_panel_create_resource_form_source_value">
               <input type="text" class="form-control" name="admin_panel_create_resource_form[source_value]" id="admin_panel_create_resource_form_source_value" value="file" hidden>
               <div id='source_value-error' class="text-danger"></div>`;
        }
      }
    }
  }

}
