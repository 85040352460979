import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="login"
export default class extends Controller {
  static targets = [ "loginBox", "registerBox", "title", "submit", "password", "emailError", "email", "forgetPassword", "checkUser" ]

  checkUser() {
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    // User is new and has to register
    if (this.emailTarget.value === 'new@new.com') {
      this.loginBoxTarget.classList.add('d-none');
      this.registerBoxTarget.classList.remove('d-none');
    }

    // User does the normal flow of login and can also reset password
    if (this.emailTarget.value.trim() === "" || !emailRegex.test(this.emailTarget.value)) {
      this.emailErrorTarget.classList.remove('d-none');
    } else {
      this.emailErrorTarget.classList.add('d-none');
      this.submitTarget.parentNode.classList.remove('d-none');
      this.checkUserTarget.classList.add('d-none');
      this.passwordTarget.parentNode.parentNode.classList.remove('d-none');
      this.emailTarget.parentNode.classList.add('d-none');
      this.forgetPasswordTarget.classList.remove('d-none');
      this.titleTarget.innerHTML = this.titleTarget.dataset.exists
    }
  }
}
