import {Controller} from "@hotwired/stimulus";
import {Turbo} from "@hotwired/turbo-rails";
import {get} from "@rails/request.js";

export default class extends Controller {
    static values = {
        editUrl: String,
        rolesHistoryUrl: String
    }

    connect() {
    }

    async edit(e) {
        await get(this.editUrlValue, {responseKind: 'turbo-stream'});
    }

    async rolesHistory(e) {
        await get(this.rolesHistoryUrlValue, {responseKind: 'turbo-stream'})
    }
}
