import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="set-details-breadcrumb"
export default class extends Controller {
  static targets = ["name"]
  connect() {
    if (this.hasNameTarget) {
      const detailsBreadcrumb = document.getElementById('details-breadcrumb-id');
      if (detailsBreadcrumb) {
        detailsBreadcrumb.textContent = this.nameTarget.textContent;
      }
    }
  }
}
