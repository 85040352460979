import { Controller } from "@hotwired/stimulus"
import Player from "@vimeo/player";
import { put } from "@rails/request.js";

// Connects to data-controller="vimeo"
export default class extends Controller {
  static targets = ["iframe"]
  static values = { setupViewUrl: String }
  lastSentProgress = 0;

  connect() {
    this.breakpoints = [25, 50, 75, 95, 100];
  }

  iframeTargetConnected(iframe) {
    iframe.player = new Player(this.element);
    ['timeupdate', 'pause', 'ended'].forEach(event => iframe.player.on(event, data => this.updateView(data)));
  }

  updateView(data) {
    const currentProgress = data.percent * 100;
    const nextBreakpoint = this.breakpoints.find(bp => currentProgress >= bp && this.lastSentProgress < bp);

    // Realizar PUT solo si el progreso actual supera un nuevo breakpoint
    if (nextBreakpoint !== undefined) {
      put(this.setupViewUrlValue, { body: { duration: data.seconds, percent: data.percent } });
      this.lastSentProgress = nextBreakpoint;
    }

    if (currentProgress >= 95) {
      document.querySelectorAll('.btn.next-action').forEach(button => button.classList.remove('d-none'));
      document.querySelector('.btn.wait-action').classList.add('d-none');
    }
  }
}
