import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-close"
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.closeModal)
  }

  closeModal = (event) => {
    const modal = event.target.closest('.modal')
    if (modal) {
      modal.classList.remove('open')
    }
  }
}
