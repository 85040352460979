import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

export default class extends Controller {

  static values = {
    url: String
  }

  async connect() {
    // Get preview content
    await get(this.urlValue, {
      responseKind: 'turbo-stream'
    })
  }
}
