import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "name",
    "surname",
    "nif",
    "birth_date",
    "street",
    "city",
    "province",
    "mobile",
    "postal_code",
    "role"
  ]

  static values = {
    url: String,
  }

  async connect() {
    this.urlValue;
  }

  submit(event) {
    let errors = 0;
    let passwordInput = document.getElementById(`user_password`);
    let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    let passwordConfirmationInput = document.getElementById(`user_password_confirmation`);
    let passwordError = document.getElementById(`error__password`);
    let passwordConfirmation_error = document.getElementById(`error__password_confirmation`);
    
    event.preventDefault();
    this.constructor.targets.forEach(target => {
      const address_attrs = ['street', 'city', 'province', 'postal_code'];
      if (target != 'role' && (!address_attrs.includes(target) || (this.roleTarget && this.roleTarget.value == 'consumidor') || !window.location.href.includes('mis-datos'))) {
        let field = this[`${target}Target`];
        let error = document.getElementById(`error__${target}`);
        let error_advice = document.getElementById(`error__advice`);

        if (field.value.trim() === "") {
          event.preventDefault();
          error.classList.remove("d-none");
          error_advice?.classList.remove("d-none");
          errors++;
        } else {
          error.classList.add("d-none");
          error_advice?.classList.add("d-none");
        }
      }
    })

    if (passwordError !== null) {
      passwordError.classList.add("d-none");
      if (!passwordRegex.test(passwordInput.value)) {
        passwordError.classList.remove("d-none");
        errors++;
      }
    }

    if (passwordConfirmation_error !== null) {
      passwordConfirmation_error.classList.add("d-none");
      if (passwordInput.value !== passwordConfirmationInput.value) {
        passwordConfirmation_error.classList.remove("d-none");
        errors++;
      }
    }

    const toast = document.getElementById("toast");

    if (errors === 0) {
      let user = {};
      if (this.roleTarget && this.roleTarget.value != 'consumidor' && window.location.href.includes('mis-datos')) {
          user = {
            name: this.nameTarget.value,
            surname: this.surnameTarget.value,
            nif: this.nifTarget.value,
            birth_date: this.birth_dateTarget.value,
            mobile: this.mobileTarget.value,
            ...(passwordInput && passwordInput.value ? { password: passwordInput.value } : {})
          }
      } else {
          user = {
            name: this.nameTarget.value,
            surname: this.surnameTarget.value,
            nif: this.nifTarget.value,
            birth_date: this.birth_dateTarget.value,
            mobile: this.mobileTarget.value,
            ...(passwordInput && passwordInput.value ? { password: passwordInput.value } : {}),
            address: {
              street: 'streetTarget' in this ? this.streetTarget.value : null,
              city: 'cityTarget' in this ? this.cityTarget.value : null,
              province: 'provinceTarget' in this ? this.provinceTarget.value : null,
              postal_code: 'postal_codeTarget' in this ? this.postal_codeTarget.value : null
            }
          }
      }
      post(this.urlValue, {
        body: {
          user
        }
      })
      .then(response => {
        if (response.ok) {
          toast.innerHTML = `<p class="success">Tus datos se han guardado correctamente.</p>`
          toast.classList.add("show");
          setTimeout(() => {
            toast.classList.remove("show")
          }, 3000);

          setTimeout(() => {
            location.reload();
          }, 3500);
      }})
      .catch(error => {
        toast.innerHTML = `<p class="error">Se ha producido un error. Vuelve a intentarlo más tarde.</p>`
        toast.classList.add("show");
        setTimeout(() => {
          toast.classList.remove("show")
        }, 3000);

        setTimeout(() => {
          location.reload();
        }, 3500);
      })
    } else {
      return false;
    }
  }
}
