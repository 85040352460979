import {Controller} from "@hotwired/stimulus"
import {put} from "@rails/request.js";

// Connects to data-controller="document"
export default class extends Controller {
  static values = {
    viewUrl: String
  }

  viewDocument(event) {
    put(this.viewUrlValue, {
      body:  { duration: 0, percent: 1 }
    })
  }
}
