import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="masterclass-disclaimer-modal"
export default class extends Controller {
  openModal(event) {
    const examId = event.currentTarget.getAttribute('link-data').split('/')[1]
    const modal = document.getElementById('masterprogram-disclaimer-modal')
    const link = document.getElementById('masterprogram-exam-link')
    modal.classList.add('open')
    link.href = `masterprogram/${examId}`
  }
}
