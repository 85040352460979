import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="widget-countdown"
export default class extends Controller {
  static targets = [ "days", "hours", "minutes" ]

  connect() {
    this.updateCountdown()
    this.interval = setInterval(() => {
      this.updateCountdown()
    }, 1000)
  }

  updateCountdown() {
    const endDateAttr = this.element.getAttribute('data-widget-countdown-end-date-value');

    const finalDate = new Date(endDateAttr);
    finalDate.setHours(finalDate.getHours() - 1);
    const currentDate = new Date()
    const timeDifference = finalDate - currentDate;

    if (timeDifference > 0) {
      if (this.hasDaysTarget && this.hasHoursTarget && this.hasMinutesTarget) {
        this.daysTarget.textContent = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
        this.hoursTarget.textContent = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        this.minutesTarget.textContent = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
      }
    } else {
      if (this.hasDaysTarget && this.hasHoursTarget && this.hasMinutesTarget) {
        this.daysTarget.textContent = 0
        this.hoursTarget.textContent = 0
        this.minutesTarget.textContent = 0
      }
    }
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
