import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";

// Connects to data-controller="authorize-challenge-on-upload"
export default class extends Controller {
  static values = {
    challengeId: Number,
    authorizingMessage: String,
    authorizedMessage: String,
    toastSuccessMessage: String,
    toastErrorMessage: String
  }

  static targets = [ "submitButton", "checkbox" ]

  connect() {
    this.submitButtonTarget.disabled = true
  }

  toggleButton() {
    this.submitButtonTarget.disabled = !this.checkboxTarget.checked
  }

  reloadPage() {
    setTimeout(() => {
      location.reload();
    }, 2000);
  }

  authorizeChallenge(event) {
    if (this.checkboxTarget.checked) {
      this.submitButtonTarget.innerHTML = this.authorizingMessageValue;
      post(`/challenges/${this.challengeIdValue}/join`, {
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        if (response.ok) {
          this.reloadPage();
          this.submitButtonTarget.innerHTML = this.authorizedMessageValue;
        } else {
          console.error('Error:', error);
        }
      }).catch(error => {
        console.error('Error:', error);
        this.reloadPage()
      });
    }
  }
}
