import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="email-lowercase"
export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTarget.addEventListener("input", () => {
      this.inputTarget.value = this.inputTarget.value.toLowerCase();
    });
  }
}
