// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import "./controllers"
import * as bootstrap from "bootstrap"
import '@fortawesome/fontawesome-free/js/all.js'


// Integrate animate.css with turbo_streams removals.
// As this is not scoped
document.addEventListener("turbo:before-stream-render", (event) => {
    if (event.target.action === "remove") {
        const targetFrame = document.getElementById(event.target.target);
        if (targetFrame && targetFrame.dataset.animateOut) {
            event.preventDefault();
            const elementBeingAnimated = targetFrame;
            elementBeingAnimated.classList.add(targetFrame.dataset.animateOut);
            elementBeingAnimated.addEventListener("animationend", () => {
                targetFrame.remove()
            })
        }
    }
})