import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.setIframeHeight();
    window.addEventListener("resize", this.setIframeHeight.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.setIframeHeight.bind(this));
  }

  setIframeHeight() {
    const iframe = this.element.querySelector(".summit__details iframe");
    if (iframe) {
      const aspectRatio = 16 / 9;
      const maxWidth = 1200;
      const newWidth = Math.min(window.innerWidth, maxWidth);
      const newHeight = newWidth / aspectRatio;
      iframe.style.height = newHeight + "px";
    }
  }
}
