import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="app-forbidden-modal"
export default class extends Controller {
  connect() {
    this.forbiddenModal = this.element;
    this.agent = navigator.userAgent;

    if (this.agent.includes('lor005_mobile_app')) {
      this.forbiddenModal.classList.add('open');
    }
  }
}
