import {Controller} from "@hotwired/stimulus"
import catalog_controller from "./catalog_controller.js";

// Connects to data-controller="physical-product-form"
export default class extends Controller {

  static values = {
    name: String,
    surname: String,
    phone: String,
    email: String,
    dni: String,
    direccion: String,
    code: String,
    city: String,
    province: String,
    challenge: Boolean,
  }

  get name() {
    return this.nameValue;
  }

  get url() {
    return this.element.action;
  }

  get surname() {
    return this.surnameValue;
  }

  get email() {
    return this.emailValue;
  }

  get phone() {
    return this.phoneValue;
  }

  get dni() {
    return this.dniValue;
  }

  get direccion() {
    return this.direccionValue;
  }

  get code() {
    return this.codeValue;
  }

  get city() {
    return this.cityValue;
  }

  get province() {
    return this.provinceValue;
  }

  connect() {
    this.nameField = this.element.form__name;
    this.dniField = this.element.form__dni;
    this.emailField = this.element.form__email;
    this.surnamesField = this.element.form__surnames;
    this.phoneField = this.element.form__phone;
    this.direccionField = this.element.form__direction;
    this.codeField = this.element.form__code;
    this.cityField = this.element.form__city;
    this.provinceField = this.element.form__province;
    this.submitButton = this.element.form__submit;

    this.nameError = document.getElementById('error__name');
    this.surnameError = document.getElementById('error__surnames');
    this.emailError = document.getElementById('error__email');
    this.phoneError = document.getElementById('error__phone');
    this.dniError = document.getElementById('error__dni');
    this.direccionError = document.getElementById('error__direction');
    this.codeError = document.getElementById('error__code');
    this.cityError = document.getElementById('error__city');
    this.provinceError = document.getElementById('error__province');

    this.isChallenge = this.challengeValue;
  }

  openModal() {
    const modalElement = document.getElementById("page-modal");
    modalElement.classList.add("open");
    this.submitButton.classList.add('d-none');
  }

  checkRequiredFields() {
    const requiredFields = [
      this.nameField.value,
      this.surnamesField.value,
      this.emailField.value,
      this.phoneField.value,
      this.dniField.value,
      this.direccionField.value,
      this.codeField.value,
      this.cityField.value,
      this.provinceField.value,
    ];

    const hasEmptyField = requiredFields.some(field => !field || field.trim() === "");


    if (hasEmptyField) {
      this.openModal();
    }

    return !hasEmptyField;
  }

  show(controller) {
    this.catalogController = controller;
    this.element.parentElement.parentElement.classList.remove('hidden');
    this.catalogController.pageContainer.classList.add('hidden');

    if (!this.isChallenge) {
      this.checkRequiredFields()

      if (this.name) {
        this.nameField.value = this.name;
      }

      if (this.surname) {
        this.surnamesField.value = this.surname
      }

      if (this.email) {
        this.emailField.value = this.email;
      }

      if (this.phone) {
        this.phoneField.value = this.phone;
      }

      if (this.dni) {
        this.dniField.value = this.dni;
      }

      if (this.direccion) {
        this.direccionField.value = this.direccion;
      }

      if (this.code) {
        this.codeField.value = this.code;
      }

      if (this.city) {
        this.cityField.value = this.city;
      }

      if (this.province) {
        this.provinceField.value = this.province;
      }
    }

    this.element.form__submit.removeAttribute('disabled')
  }

  hide() {
    this.element.parentElement.parentElement.classList.add('hidden');
  }

  validateForm() {
    let isValid = true;

    if (this.nameField.value === '') {
      isValid = false;
      this.nameError.classList.remove('d-none');
    } else {
      this.nameError.classList.add('d-none');
    }

    if (this.surnamesField.value === '') {
      isValid = false;
      this.surnameError.classList.remove('d-none');
    } else {
      this.surnameError.classList.add('d-none');
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (this.emailField.value === '' || !emailRegex.test(this.emailField.value)) {
      isValid = false;
      this.emailError.classList.remove('d-none');
    } else {
      this.emailError.classList.add('d-none');
    }

    const phoneRegex = /^[0-9]{9}$/;
    if (this.phoneField.value === '' || !phoneRegex.test(this.phoneField.value)) {
      isValid = false;
      this.phoneError.classList.remove('d-none');
    } else {
      this.phoneError.classList.add('d-none');
    }

    const dniRegex = /^[a-zA-Z]?\d{7,8}[a-zA-Z]$/;
    if (this.dniField.value === '' || !dniRegex.test(this.dniField.value)) {
      isValid = false;
      this.dniError.classList.remove('d-none');
    } else {
      this.dniError.classList.add('d-none');
    }

    if (this.direccionField.value === '') {
      isValid = false;
      this.direccionError.classList.remove('d-none');
    } else {
      this.direccionError.classList.add('d-none');
    }

    const codeRegex = /^[0-9]{5}$/;
    if (this.codeField.value === '' || !codeRegex.test(this.codeField.value)) {
      isValid = false;
      this.codeError.classList.remove('d-none');
    } else {
      this.codeError.classList.add('d-none');
    }

    if (this.cityField.value === '') {
      isValid = false;
      this.cityError.classList.remove('d-none');
    } else {
      this.cityError.classList.add('d-none');
    }

    if (this.provinceField.value === '') {
      isValid = false;
      this.provinceError.classList.remove('d-none');
    } else {
      this.provinceError.classList.add('d-none');
    }

    return isValid;
  }

  async submit(event) {
    event.preventDefault();

    if (this.isChallenge) {
      this.validateForm();
      if (!this.validateForm()) {
        return;
      }
    }
    this.catalogController.showSpinner(this.element);

    const formData = new FormData(this.element);
    const queryParams = new URLSearchParams(formData).toString();

    const response = await fetch(`${this.url}?${queryParams}`);

    if (response.status === 200) {
        dataLayer.push({
          event: 'uaevent',
          event_name: 'body_button_click',
          eventAction: 'select',
          eventCategory: 'content page',
          eventLabel: 'aceptar',
          cta_name: 'aceptar',
          link_url: '',
          module_name: this.name,
      });
      this.catalogController.hideModal();
      this.hide();
      const data = await response.json();
      this.catalogController.hideSpinner({
        content: data.html
      });
      this.catalogController.pageContainer.classList.remove('hidden')
    }
  }
}
