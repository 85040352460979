import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['token'];

  connect () {
    this.element.setAttribute('novalidate', true)
    this.element.addEventListener('blur', this.onBlur, true)
  }

  disconnect () {
    this.element.removeEventListener('blur', this.onBlur)
  }

  onBlur = (event) => {
    this.validateField(event.target)
  }

  async submitForm (event) {
    event.preventDefault();
    const submitButton = document.querySelector('input[name="commit"]');
    if (!this.validateForm()) {
      event.preventDefault()
      this.firstInvalidField.focus()
    } else {
      submitButton.disabled = true;
      await grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise.execute(this.tokenTarget.dataset.sitekey, { action: this.tokenTarget.dataset.action }).then(token => {
          this.tokenTarget.value = token;
          event.target.form.submit();
        })
      })
    }
  }

  validateForm () {
    let isValid = true
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false
    })
    return isValid
  }

  validateField (field) {
    if (!this.shouldValidateField(field))
      return true
    const isValid = field.checkValidity()
    field.classList.toggle('invalid', !isValid)
    this.refreshErrorForInvalidField(field, isValid)
    return isValid
  }

  shouldValidateField (field) {
    return !field.disabled && !['file', 'reset', 'submit', 'button'].includes(field.type)
  }

  refreshErrorForInvalidField (field, isValid) {
    this.removeExistingErrorMessage(field)
    if (!isValid)
      this.showErrorForInvalidField(field)
  }

  removeExistingErrorMessage (field) {
    const fieldContainer = field.closest('.field')
    if(!fieldContainer)
      return;
    const existingErrorMessageElement = fieldContainer.querySelector('.error')
    if (existingErrorMessageElement)
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
  }

  showErrorForInvalidField (field) {
    field.parentNode.lastElementChild.insertAdjacentHTML('afterend', this.buildFieldErrorHtml(field))
  }

  buildFieldErrorHtml (field) {
    let validationMessage = field.validationMessage
    if (field.validity.valueMissing) {
      validationMessage = this.tokenTarget.title
    }
    return '<p class="error">'+validationMessage+'</p>'
  }

  get formFields () {
    return Array.from(this.element.elements)
  }

  get firstInvalidField () {
    return this.formFields.find(field => !field.checkValidity())
  }
}
