import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="training"
export default class extends Controller {
  static values = { documentsUrl: String }

  async connect() {
    await get(this.documentsUrlValue, { responseKind: 'turbo-stream' });
  }
}
