import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notification-widget"
export default class extends Controller {
  static targets = ["challengeWidget", "challengeModal", "minimizeChallengeWidget", "openChallengeWidget", "showChallengeModal", "hideChallengeModal"];

  connect() {
    this.minimizeChallengeWidgetTarget.addEventListener("click", () => {
      this.challengeWidgetTarget.classList.add("closed");

      if (this.challengeModalTarget.classList.contains("open")) {
        this.challengeModalTarget.classList.remove("open");
      }
    });

    this.openChallengeWidgetTarget.addEventListener("click", () => {
      this.closeAllWidgets();
      this.challengeWidgetTarget.classList.remove("closed");
    });

    this.showChallengeModalTarget.addEventListener("click", () => {
      this.challengeModalTarget.classList.add("open");
    });

    this.hideChallengeModalTarget.addEventListener("click", () => {
      this.challengeModalTarget.classList.remove("open");
    });
  }

  closeAllWidgets() {
    const widgets = document.querySelectorAll('.widget');
    console.log(widgets);
    widgets.forEach(widget => {
      widget.classList.add('closed');
    });
  }
}
