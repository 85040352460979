import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';
import { get } from '@rails/request.js'

// Connects to data-controller='slim'
export default class extends Controller {
  static values = {
    searchUrl: String,                                                            // URL to execute the query against
    searchFields: Array,                                                          // Fields to search for
    readFields: Array,                                                            // Fields to read from the endpoint
    tagNames: Array,                                                              // Fields which contain tags for the result
    limit: { type: Number, default: 20 },                                         // Maximum results to return from the server
    shortQueryMessage: { type: String, default: 'Escribe al menos 1 caracter' },  // Message to return when the query is too short
    addable: Boolean,                                                             // Whether to allow adding new values
    allowDeselect: Boolean,                                                       // Whether to allow deselecting values
  }

  connect() {
    const isMultiSelect = this.element.hasAttribute('multiple');

    // Remember to also update `admin-panel--select-manager` if the configuration is modified here,
    // as that controller manually copies this into a new SlimSelect and destroys the old one
    new SlimSelect({
      select: this.element,
      settings: {
        closeOnSelect: !isMultiSelect,
        showSearch: true,
        allowDeselect: isMultiSelect || this.allowDeselectValue,
      },
      events: {
        addable: this.addableValue ? (value) => { return value } : null,
        afterChange: this.element.classList.contains('recurring_select') ? (newVal) => {
          // This callback maintains functionality when used with Slim
          $(`#${this.element.id}`).recurring_select('changed');
        } : null,
        search: this.searchUrlValue != '' ? (search, currentData) => {
          if (search.length < 1) { // Avoid querying when the provided value is small
            return Promise.reject(this.shortQueryMessageValue)
          } else {
            return get(this.searchUrlValue, {
              query: {
                query: search,
                attrs: this.searchFieldsValue,
                limit: this.limitValue
              },
              responseKind: 'json'
            }).then((response) => response.json) // Obtain the json from the response; Rails function
              .then((data) => {
                this.element.modelsData = data
                return Promise.resolve(
                  data
                    .filter((model) => { // Filter already present data
                      return !currentData.some((optionData) => {
                        return optionData.value === model.id
                      })
                    })
                    .map((model) => { // Map model to Options
                      let name = this.readFieldsValue.map((name) => model[name]).join(' ');
                      let tags = [];
                      if (this.tagNamesValue) {
                        tags = this.tagNamesValue.flatMap((tag) => model[tag]).filter((tag) => tag != null);
                        if (tags.length > 0) {
                          tags = `[${tags.join('|')}]`;
                        }
                      }
                    return {
                      text: `${name} ${tags}`.trim(),
                      value: model.id
                    }
                  })
                );
              });
          }
        } : null
      }
    });
  }
}
