import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

export default class extends Controller {

  static values = {
    optionsUrl: String,
    searchUrl: String
  }

  static targets = [
    'companyId',
    'companyZipCode',
    'companyZipCodeError',
    'companyNif',
    'pharmacySelect',
  ]

  companyZipCodeTargetConnected(zipCode) {
    zipCode.addEventListener("blur", this.optionChange.bind(this));
  }

  optionChange(event) {
    const selected = event.target.value;
    const validZipCodeRegexES = /^\d{5}$/;
    const validZipCodeRegexPT = /^\d{4}-\d{3}$/;
    if (validZipCodeRegexES.test(selected) || validZipCodeRegexPT.test(selected)) {
      this.companyZipCodeErrorTarget.classList.add('d-none');
      get(this.optionsUrlValue, {
        responseKind: "turbo-stream",
        query: {
          zip: selected,
          target: this.pharmacySelectTarget.id
        }
      });
    } else {
      this.companyZipCodeErrorTarget.classList.remove('d-none');
    }
  }
}
