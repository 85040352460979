import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tickets-modal"
export default class extends Controller {
  static targets = ["pdf", "image", "modalImage", "modalPdf"]
  static values = { url: String }

  openImage(event) {
    this.open(event, this.imageTarget, this.modalImageTarget)
  }

  closeImage(event) {
    this.close(this.modalImageTarget)
  }

  openPdf(event) {
    this.open(event, this.pdfTarget, this.modalPdfTarget)
  }

  closePdf(event) {
    this.close(this.modalPdfTarget)
  }

  open(event, target, modalTarget) {
    event.preventDefault()
    target.src = event.currentTarget.dataset.fullImageUrl
    modalTarget.classList.add('open')
  }

  close(modalTarget) {
    modalTarget.classList.remove('open')
  }
}
