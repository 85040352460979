import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {
  connect() {
    const bodyElement = document.querySelector('body');
    const menuButton = document.getElementById('menu-mobile');
    const closeButton = document.querySelector('.hamburguer__close');
    const menu = document.getElementById('nav-hamburguer');
    const submenuButtons = document.querySelectorAll('.hamburguer__list button');
    const overlayElement = document.querySelector('.hamburguer__overlay');

    // Mostramos el menu
    function showMenu() {
      menu.style.right = '0';
      bodyElement.classList.add('open');
    }

    // Ocultamos el menú
    function hideMenu() {
      menu.style.right = '-125%';
      bodyElement.classList.remove('open');
    }

    // Ocultar submenu
    function hideAllSubmenus() {
      submenuButtons.forEach(function (button) {
        button.classList.remove('active');
        const submenu = button.nextElementSibling;
        submenu.style.display = 'none';
      });
    }

    // Mostrar menu al hacer click en boton
    menuButton.addEventListener('click', function () {
      showMenu();
    });

    // Cerrar menu dando al boton de cierre
    closeButton.addEventListener('click', function () {
      hideMenu();
      hideAllSubmenus(); // Ocultar todos los submenús al cerrar el menú
    });

    // Cerrar menu dando al boton de cierre
    overlayElement.addEventListener('click', function () {
      hideMenu();
      hideAllSubmenus(); // Ocultar todos los submenús al cerrar el menú
    });

    // Mostramos u ocultamos submenu al hacer click en el boton
    submenuButtons.forEach(function (button) {
      button.addEventListener('click', function () {
        const submenu = button.nextElementSibling;
        if (submenu.style.display === 'block') {
          submenu.style.display = 'none';
          button.classList.remove('active');
        } else {
          hideAllSubmenus(); // Ocultar todos los submenús
          submenu.style.display = 'block';
          button.classList.add('active');
        }
      });
    });
  }
}
