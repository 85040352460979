import { Controller } from "@hotwired/stimulus"
  import Player from '@vimeo/player';
  import { put } from "@rails/request.js";

  // Connects to data-controller="open-video-modal"
  export default class extends Controller {
    static targets = ["modal", "iframe"];
    static values = { setupViewUrl: String }
    player = null;
    lastSentProgress = 0;

    connect() {
      this.modal = document.getElementById("video-modal");
      this.iframe = this.modal.querySelector("iframe");
      this.breakpoints = [25, 50, 75, 95, 100];
    }

    open(event) {
      const videoId = event.currentTarget.dataset.videoId;
      this.iframe.src = `https://player.vimeo.com/video/${videoId}`;
      this.modal.classList.remove("closed");

      this.player = new Player(this.iframe);

      if (this.hasSetupViewUrlValue) {
        ['timeupdate', 'pause', 'ended'].forEach(event => this.player.on(event, data => this.updateView(data)));
      }
    }

    close() {
      this.modal.classList.add("closed");
      if (this.player) {
        this.player.pause();
      }
      this.iframe.src = "";
    }

    updateView(data) {
      const currentProgress = data.percent * 100;
      const nextBreakpoint = this.breakpoints.find(bp => currentProgress >= bp && this.lastSentProgress < bp);

      if (nextBreakpoint !== undefined) {
        put(this.setupViewUrlValue, { body: { duration: data.seconds, percent: data.percent } });
        this.lastSentProgress = nextBreakpoint;
      }
    }
  }
