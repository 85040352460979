import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload-file"
export default class extends Controller {
  static targets = ["container", "fileInput", "labelText", "uploadIcon", "uploadBtn", "label", "toast"];

  connect() {
    this.uploadBtnTarget.style.display = 'none';
    this.labelTarget.addEventListener('click', () => this.openFileInput());
    this.fileInputTarget.addEventListener('change', (e) => this.handleFileChange(e));
    this.labelTarget.addEventListener('dragover', (e) => this.handleDragOver(e));
    this.labelTarget.addEventListener('dragleave', () => this.handleDragLeave());
    this.labelTarget.addEventListener('drop', (e) => this.handleDrop(e));
    this.uploadBtnTarget.addEventListener('click', () => this.uploadFile());
  }

  openFileInput() {
    this.fileInputTarget.click();
  }

  handleFileChange(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const validExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
      const fileExtension = selectedFile.name.toLowerCase().slice(-4);

      if (validExtensions.includes(fileExtension)) {
        this.labelTextTarget.innerHTML = `${this.containerTarget.dataset.uploadSuccessMessage}<br /><br /> <b>${selectedFile.name}</b>`;
        this.uploadIconTarget.style.display = 'none';
        this.uploadBtnTarget.style.display = 'block';
      } else {
        this.showToast('file-error');
        this.resetFileInput();
      }
    } else {
      this.resetFileInput();
    }
  }

  handleDragOver(e) {
    e.preventDefault();
    this.labelTarget.classList.add('dragover');
  }

  handleDragLeave() {
    this.labelTarget.classList.remove('dragover');
  }

  handleDrop(e) {
    e.preventDefault();
    this.labelTarget.classList.remove('dragover');
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      const validExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
      const fileExtension = droppedFile.name.toLowerCase().slice(-4);

      if (validExtensions.includes(fileExtension)) {
        this.labelTextTarget.innerHTML = `${this.containerTarget.dataset.uploadSuccessMessage}<br /><br /> <b>${droppedFile.name}</b>`;
        this.uploadIconTarget.style.display = 'none';
        this.fileInputTarget.files = e.dataTransfer.files;
        this.uploadBtnTarget.style.display = 'block';
      } else {
        this.showToast('file-error');
        this.resetFileInput();
      }
    }
  }

  uploadFile() {
    const formData = new FormData();
    var currentURL = window.location.href;
    formData.append('file', this.fileInputTarget.files[0]);
    const brand = currentURL.match(/communities\/([a-zA-Z0-9_-]+)/);
    if (brand) {
      formData.append('slug', brand[1]);
    }
    this.uploadBtnTarget.innerHTML = this.containerTarget.dataset.buttonUploading;
    fetch(`/challenges/${this.containerTarget.dataset.challengeId}/upload`, {
      method: 'POST',
      body: formData,
    }).then(response => {
      if (response.ok) {
        this.showToast('success');
        this.uploadBtnTarget.innerHTML = this.containerTarget.dataset.buttonUploaded;
        setTimeout(() => {
          this.resetFileInput();
          this.uploadBtnTarget.innerHTML = this.containerTarget.dataset.buttonUpload;
        }, 3000);
      } else {
        response.json().then(json => {
          this.showToast('error', json.error.message);
          console.error('Error uploading file:', json.error);
          this.uploadBtnTarget.innerHTML = 'Error'
          setTimeout(() => {
            this.resetFileInput();
            this.uploadBtnTarget.innerHTML = this.containerTarget.dataset.buttonUpload;
          }, 3000);
        })
      }
    });
  }

  showToast(string, message) {
    if (string === 'success') {
      this.toastTarget.innerHTML = `<p class="success">${this.containerTarget.dataset.uploadToastSuccessMessage}</p>`
    } else if (string === 'file-error') {
      this.toastTarget.innerHTML = `<p class="error"><p class="success">${this.containerTarget.dataset.uploadToastErrorFileMessage}</p>`;
    } else {
      this.toastTarget.innerHTML = `<p class="error"><p class="success">${this.containerTarget.dataset.uploadToastErrorTicketMessage} ${message}</p>`
    }
    this.toastTarget.classList.add("show");

    setTimeout(() => {
      this.toastTarget.classList.remove("show")
    }, 3000);
  }

  resetFileInput() {
    this.fileInputTarget.value = '';
    this.labelTextTarget.innerHTML = `<p class="success">${this.containerTarget.dataset.uploadResetMessage}`;
    this.uploadIconTarget.style.display = 'inline';
    this.uploadBtnTarget.style.display = 'none';
  }
}
