import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    const today = new Date();
    const month = today.getMonth() + 1; // Mes actual
    const day = today.getDate();

    // Mostramos la modal y guardamos en sessionStorage la vez que entra siempre que sea diciembre y del 1 al 24
    if (month === 12 && day <= 24 && !sessionStorage.getItem("modalShown")) {
      this.showModal();
      sessionStorage.setItem("modalShown", "true");
    }

    const openButton = document.getElementById("calendario-adviento");
    if (openButton) {
      openButton.addEventListener("click", () => {
        this.showModal();
      });
    }
  }

  showModal() {
    this.modalTarget.classList.add("open", "show");
  }

  closeModal() {
    this.modalTarget.classList.remove("open", "show");
  }
}
