import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['name', 'surname', 'email', "id", 'phone', 'address', 'name_farmacy', 'postal_code', 'city', 'province', 'radio', 'form'];

  validateForm(event) {
    event.preventDefault();

    const name = this.nameTarget;
    const surname = this.surnameTarget;
    const email = this.emailTarget;
    const id = this.idTarget;
    const phone = this.phoneTarget;
    const address = this.addressTarget;
    const name_farmacy = this.name_farmacyTarget;
    const postal_code = this.postal_codeTarget;
    const city = this.cityTarget;
    const province = this.provinceTarget;

    const selectedRadioValue = this.getSelectedRadioValue();

    const validationErrors = this.validateField('name', name) + this.validateField('surname', surname) + this.validateField('email', email) + this.validateField('id', id) + this.validateField('phone', phone) + this.validateField('address', address) + this.validateField('name_farmacy', name_farmacy) + this.validateField('postal_code', postal_code) + this.validateField('city', city) + this.validateField('province', province);

    if (validationErrors == 0) {
      this.submitForm(selectedRadioValue);
    } else {
      // console.log('validation errors')
    }
  }

  validateField(fieldName, fieldTarget) {
    const errorTarget = fieldTarget.nextElementSibling;
    const value = fieldTarget.value;

    if (fieldName === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value) || !value.trim()) {
        errorTarget.classList.remove('d-none');
        return 1;
      } else {
        errorTarget.classList.add('d-none');
        return 0;
      }
    } else if (fieldName === 'id') {
      const idRegex = /^[a-zA-Z]?\d{7,8}[a-zA-Z]$/;
      if (!idRegex.test(value) || !value.trim()) {
        errorTarget.classList.remove('d-none');
        return 1;
      } else {
        errorTarget.classList.add('d-none');
        return 0;
      }
    } else if (fieldName === 'phone') {
      const phoneRegex = /^\d{9}$/;
      if (!phoneRegex.test(value) || !value.trim()) {
        errorTarget.classList.remove('d-none');
        return 1;
      } else {
        errorTarget.classList.add('d-none');
        return 0;
      }
    } else if (fieldName === 'number') {
      const validZipCodeRegexES = /^\d{5}$/;
      const validZipCodeRegexPT = /^\d{4}-\d{3}$/;
      if (validZipCodeRegexES.test(selected) || validZipCodeRegexPT.test(selected) || !value.trim()) {
        errorTarget.classList.remove('d-none');
        return 1;
      } else {
        errorTarget.classList.add('d-none');
        return 0;
      }
    } else {
      if (!value.trim()) {
        errorTarget.classList.remove('d-none');
        return 1;
      } else {
        errorTarget.classList.add('d-none');
        return 0;
      }
    }
  }

  getSelectedRadioValue() {
    const selectedRadio = this.radioTargets.find(radio => radio.checked);
    return selectedRadio ? selectedRadio.value : null;
  }

  submitForm(selectedRadioValue) {

    const event_id = this.formTarget.dataset.eventid;
    const session_id = selectedRadioValue;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const submitButton = document.querySelector('#form__submit');
    submitButton.disabled = true;

    fetch(`/calendar/${event_id}/session/${session_id}/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify(
        {
          user: {
            name: this.nameTarget.value,
            surname: this.surnameTarget.value,
            email: this.emailTarget.value,
            nif: this.idTarget.value,
            phone_number: this.phoneTarget.value,
            address: {
              street: this.name_farmacyTarget.value + ' | ' + this.addressTarget.value,
              postal_code: this.postal_codeTarget.value,
              city: this.cityTarget.value,
              province: this.provinceTarget.value,
            }
          }
        },
      )
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        document.getElementById("subscription-modal").classList.add('open');
        if (data.success === true) {
          document.getElementById("success-message").classList.remove('d-none');
        } else if (data.error && data.error.user && data.error.user.messages && data.error.user.messages.email && data.error.user.messages.email[0] === "registered") {
          document.getElementById("registered-message").classList.remove('d-none');
        } else if (data.error && data.error.subscription && data.error.subscription.messages && data.error.subscription.messages === 'already_subscribed') {
          document.getElementById("already-message").classList.remove('d-none');
        } else {
          document.getElementById("error-message").classList.remove('d-none');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        document.getElementById("error-message").classList.remove('d-none');
      }).finally(() => {
        submitButton.disabled = false;
      });
  }
}