import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static targets = [
        "reboticaSubMenu",
        "reboticaButton",
        'productsButton',
        'productsSubMenu'
    ]

    connect() {
        this.reboticaliElement = this.reboticaButtonTarget.parentElement;
        this.reboticaButton = this.reboticaButtonTarget.cloneNode(true);
        this.productliElement = this.productsButtonTarget.parentElement;
        this.productsButton = this.productsButtonTarget.cloneNode(true);
    }

    removeButtons() {
        this.reboticaButtonTarget.remove();
        this.productsButtonTarget.remove();
    }

    addButtons() {
        this.reboticaliElement.appendChild(this.reboticaButton);
        this.productliElement.appendChild(this.productsButton);
    }

    openProducts() {
        this.productsSubMenuTarget.classList.add('open');
        this.removeButtons();
    }

    closeProducts() {
        this.productsSubMenuTarget.classList.remove('open');
        this.addButtons();
    }

    openRebotica() {
        this.reboticaSubMenuTarget.classList.add('open');
        this.removeButtons();
    }

    closeRebotica() {
        this.reboticaSubMenuTarget.classList.remove('open');
        this.addButtons();
    }
}
