import { Controller } from "@hotwired/stimulus"
import Player from '@vimeo/player';

// Connects to data-controller="plugins--vimeo"
export default class extends Controller {
  static targets = ["trainingModal", "iframe"]
  player = null;

  connect() {
    this.player = new Player(this.iframeTarget);
  }

  showTrainingModal() {
    this.trainingModalTarget.classList.remove('d-none');
  }

  closeTrainingModal() {
    this.trainingModalTarget.classList.add('d-none');
    if(this.hasIframeTarget) {
      this.stopVideo();
    }
  }

  stopVideo() {
    this.player.pause();
  }
}
