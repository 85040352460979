import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="live-widget"
export default class extends Controller {
  static targets = ["minimize", "widget", "open"]

  connect() {
    this.minimizeTarget.addEventListener("click", this.toggleWidget.bind(this));
    this.openTarget.addEventListener("click", this.openWidget.bind(this));
  }

  disconnect() {
    this.minimizeTarget.removeEventListener("click", this.toggleWidget.bind(this));
    this.openTarget.removeEventListener("click", this.openWidget.bind(this));
  }

  toggleWidget() {
    this.widgetTarget.classList.add("closed");
  }

  openWidget() {
    this.closeAllWidgets();
    this.widgetTarget.classList.remove("closed");
  }

  closeAllWidgets() {
    const widgets = document.querySelectorAll('.widget');
    widgets.forEach(widget => {
      widget.classList.add('closed');
    });
  }
}
