import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invite"
export default class extends Controller {
  static targets = ["container", "shareBtn", "whatsappBtn", "emailBtn", "invitationLinkImage", "toast"]

  connect() {
    this.url = this.containerTarget.dataset.invitationUrlToken;

    console.log(this.url)
  }

  async copyLink() {
    const linkToCopy = this.invitationLinkImageTarget.getAttribute('data-link');
    try {
        await navigator.clipboard.writeText(linkToCopy);
        this.showToast('success');
    } catch (err) {
        console.error('Error al copiar el enlace: ', err);
    }
}

  shareOnWhatsapp() {
    const whatsappUrl = `whatsapp://send?text=${this.containerTarget.dataset.whatsapp} ${this.url}`;

    window.open(whatsappUrl, '_blank');
  }

  shareByEmail() {
    window.location.href = `mailto:%20?subject=${this.containerTarget.dataset.subjectText}&body=${this.containerTarget.dataset.bodyText} ${this.url}`;
  }

  showToast(string) {
    if (string === 'success') {
      this.toastTarget.innerHTML = `<p class="success">${this.containerTarget.dataset.toastSuccessMessage}</p>`
    }
    this.toastTarget.classList.add("show");

    setTimeout(() => {
      this.toastTarget.classList.remove("show")
    }, 3000);
  }
}
