import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="masterprogram-ranking-individual"
export default class extends Controller {
  static targets = [ "ranking", "button" ]
  static values = { currentPage: Number, itemsPerPage: Number, displayType: String }

  connect() {
    this.itemsPerPageValue = 5;
    this.currentPageValue = 1;
    this.displayTypeValue = this.displayTypeValue || 'flex';
    this.paginate();
    this.highlightActiveButton();
    console.log(this.rankingTarget.style.maxHeight)
  }

  toggle() {
    console.log(this.rankingTarget.style.maxHeight)
    if (this.rankingTarget.style.maxHeight == "0px") {
      this.expand()
    } else {
      this.collapse()
    }
  }

  expand() {
    this.rankingTarget.style.maxHeight = "800px"
    this.buttonTarget.innerText = this.buttonTarget.dataset.close
  }

  collapse() {
    this.rankingTarget.style.maxHeight = "0px"
    this.rankingTarget.style.minHeight = "unset"
    this.buttonTarget.innerText = this.buttonTarget.dataset.view
  }

  paginate() {
    const items = Array.from(this.element.querySelectorAll('.ranking-row'));
    items.forEach((item, index) => {
      item.style.display = (index >= (this.currentPageValue - 1) * this.itemsPerPageValue && index < this.currentPageValue * this.itemsPerPageValue) ? this.displayTypeValue : 'none';
    });
    this.highlightActiveButton();
  }

  highlightActiveButton() {
    const buttons = Array.from(this.element.querySelectorAll('.page__pagination button'));
    buttons.forEach(button => button.classList.remove('active'));
    const activeButton = buttons[this.currentPageValue - 1];
    if (activeButton) activeButton.classList.add('active');
  }

  next() {
    const items = Array.from(this.element.querySelectorAll('.ranking-row'));
    if (this.currentPageValue * this.itemsPerPageValue < items.length) {
      this.currentPageValue++;
      this.paginate();
    }
  }

  previous() {
    if (this.currentPageValue > 1) {
      this.currentPageValue--;
      this.paginate();
    }
  }
}
