import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="catalog"
export default class extends Controller {

  static targets = [
    'modal',
    'btnOk',
    'pageContainer',
    'spinner',
    'printVoucher',
    'link'
  ]

  static outlets = ['physical-product-form']

  static values = {
    physical: Boolean
  }

  physicalProductFormOutletConnected(outlet, element) {
    this.exchangeForm = outlet;
  }

  connect() {
    this.modal = this.modalTarget;
    this.btnOk = this.btnOkTarget;
    this.productName = this.data.get("productName");
    this.productId = this.data.get("productId");
    this.pageContainer = this.pageContainerTarget;
    this.printVoucher = this.printVoucherTarget;
    this.link = this.linkTarget;

    // Whether the details are for a physical product
    // or not
    this.isPhysical = this.physicalValue;

    this.spinner = this.spinnerTarget;
    this.isLoading = false;
    this.originalContent = this.pageContainer.innerHTML;
  }

  showSpinner(container) {
    if (!this.isLoading) {
      container.innerHTML = this.spinner.innerHTML;
      this.isLoading = true;
    }
  }

  showPrintButton(options = {}) {
    this.linkTarget.href = options.content
    this.printVoucherTarget.style.display = 'block';
  }

  hideSpinner(options = {}) {
    if (this.isLoading) {
      this.isLoading = false;
      if (options.content) {
        if (options.container) {
          options.container.innerHTML = options.content;
        } else {
          this.pageContainer.innerHTML = options.content;
        }
      } else {
        if (options.container) {
          options.container.innerHTML = this.originalContent;
        } else {
          this.pageContainer.innerHTML = this.originalContent;
        }
      }
    }
  }

  hideModal() {
    this.modal.classList.remove('open');
  }

  openModal() {
    this.modal.classList.add('open');
  }

  async clickOk(event) {
    event.preventDefault();

    this.hideModal();
    if (this.isPhysical) {
      // If product is physical, then we must
      // show the form and do submition from there
      this.exchangeForm.show(this);
    } else {

      this.showSpinner(this.pageContainer);

      const url = this.btnOk.href;
      const response = await fetch(url);

      if (response.status === 200) {
        dataLayer.push({
          event: 'uaevent',
          event_name: 'buy_now',
          eventAction: 'click to buy',
          eventCategory: 'Ecommerce',
          eventLabel: `${this.productName}::${this.productId}`,
          product_info: `${this.productName}::${this.productId}`
        });
        this.hideModal();
        const data = await response.json();
        this.showPrintButton({
          content: data.voucher_url
        });
        
        this.hideSpinner({
          content: data.html
        });
      }
    }
  }
}
