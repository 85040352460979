import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-open"
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.openModal)
  }

  openModal = (event) => {
    const modalId = this.element.getAttribute('data-modal-id')
    const modal = document.querySelector(`#${modalId}`)
    if (modal) {
      modal.classList.add('open')
    }
  }
}
