import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mainHexagon", "productsHexagon", "pharmaHexagon", "calendarHexagon"];

  connect() {
    this.showOnly("mainHexagon");
  }

  show(event) {
    const partialToShow = event.currentTarget.dataset.navbarParam;
    this.showOnly(partialToShow);
  }

  showOnly(targetName) {
    this.constructor.targets.forEach((target) => {
      if (this.targets.has(target)) {
        const elements = this.targets.findAll(target);
        elements.forEach(element => {
          if (target === targetName) {
            element.classList.remove("d-none");
          } else {
            element.classList.add("d-none");
          }
        });
      }
    });
  }
}
