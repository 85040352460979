import {Controller} from "@hotwired/stimulus"
import physicalcatalog_controller from "./catalogchallenge_controller.js";

// Connects to data-controller="physicalchallenge-product-form"
export default class extends Controller {

  static values = {
    name: String,
    surname: String,
    phone: String,
    email: String,
    dni: String,
    direccion: String,
    code: String,
    city: String,
    province: String,
  }

  get name() {
    return this.nameValue;
  }

  get url() {
    return this.element.action;
  }

  get surname() {
    return this.surnameValue;
  }

  get email() {
    return this.emailValue;
  }

  get phone() {
    return this.phoneValue;
  }

  get dni() {
    return this.dniValue;
  }

  get direccion() {
    return this.direccionValue;
  }

  get code() {
    return this.codeValue;
  }

  get city() {
    return this.cityValue;
  }

  get province() {
    return this.provinceValue;
  }

  connect() {
    this.nameField = this.element.form__email;
    this.dniField = this.element.form__dni;
    this.emailField = this.element.form__email;
    this.surnamesField = this.element.form__surnames;
    this.phoneField = this.element.form__phone;
    this.direccionField = this.element.form__direction;
    this.codeField = this.element.form__code;
    this.cityField = this.element.form__city;
    this.provinceField = this.element.form__province;

    this.nameError = this.element.querySelector('.error__name');
    this.surnameError = this.element.querySelector('.error__surname');
    this.emailError = this.element.querySelector('.error__email');
    this.phoneError = this.element.querySelector('.error__phone');
    this.dniError = this.element.querySelector('.error__dni');
    this.direccionError = this.element.querySelector('.error__direccion');
    this.codeError = this.element.querySelector('.error__code');
    this.cityError = this.element.querySelector('.error__city');
    this.provinceError = this.element.querySelector('.error__province');
  }

  show(controller) {
    this.catalogController = controller;
    this.element.parentElement.parentElement.classList.remove('hidden');
    this.catalogController.pageContainer.classList.add('hidden');

    if (this.name) {
      this.nameField.value = this.name;
    }

    if (this.surname) {
      this.surnamesField.value = this.surname
    }

    if (this.email) {
      this.emailField.value = this.email;
    }

    if (this.phone) {
      this.phoneField.value = this.phone;
    }

    if (this.dni) {
      this.dniField.value = this.dni;
    }

    if (this.direccion) {
      this.direccionField.value = this.direccion;
    }

    if (this.code) {
      this.codeField.value = this.code;
    }

    if (this.city) {
      this.cityField.value = this.city;
    }

    if (this.province) {
      this.provinceField.value = this.province;
    }

    this.element.form__submit.removeAttribute('disabled')
  }

  hide() {
    this.element.parentElement.parentElement.classList.add('hidden');
  }

  /* validateForm() {
    let isValid = true;
    let errorMessage = "";

    if (!this.name) {
      isValid = false;
      this.nameError.classList.remove('d-none');
    } else {
      this.nameError.classList.add('d-none');
    }
  } */

  async submit(event) {
    event.preventDefault();

    const formData = new FormData(this.element);
    const queryParams = new URLSearchParams(formData).toString();

    const response = await fetch(`${this.url}?${queryParams}`);

    if (response.status === 200) {
        dataLayer.push({
          event: 'uaevent',
          event_name: 'body_button_click',
          eventAction: 'select',
          eventCategory: 'content page',
          eventLabel: 'aceptar',
          cta_name: 'aceptar',
          link_url: '',
          module_name: this.name,
      });
      this.catalogController.hideModal();
      this.hide();
      const data = await response.json();
      this.catalogController.hideSpinner({
        content: data.html
      });
      this.catalogController.pageContainer.classList.remove('hidden')
    }
  }
}
