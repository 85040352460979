import {Controller} from "@hotwired/stimulus";
import {Carousel} from "bootstrap";

export default class extends Controller {
    connect() {
        this.carousel = new Carousel(this.element, {
            ride: 'carousel',
            interval: 5000
        });
    }
}
