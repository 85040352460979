import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar-scroll"
export default class extends Controller {
  connect() {
    if (!this.isPageRefreshed()) {
      this.scrollToPosition();
    }
  }

  isPageRefreshed() {
    return performance.navigation.type === 1;
  }

  scrollToPosition() {
    window.scrollBy(0, 500);
  }
}
