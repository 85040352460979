import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

export default class extends Controller {
  connect() {
    const selectors = ["#company-city-select"];
    
    selectors.forEach(selector => {
      const element = this.element.querySelector(selector);
      if (element) {
        this.initializeSlimSelectForElement(element);
      }
    });
  }
  
  toggleFields(event) {
    const attributeName = event.target.value;
    const nestedFields = event.target.closest('.nested-fields');
  
    const caeFileGroup = nestedFields.querySelector('.cae-file-group');
    const additionalSelectGroups = nestedFields.querySelectorAll('.additional-select-group');
  
    caeFileGroup.style.display = 'none';
    additionalSelectGroups.forEach(group => {
      group.style.display = 'none';
    });
  
    const fieldMap = {
      'company_cae': caeFileGroup,
      'company_brand': nestedFields.querySelector('#segment-name-company-brand'),
      'company_city': nestedFields.querySelector('#segment-name-company-city'),
      'company_postal_code': nestedFields.querySelector('#segment-name-company-postal-code')
    };
  
    if (fieldMap[attributeName]) {
      fieldMap[attributeName].style.display = 'block';
    }
  }
  
  initializeSlimSelectForElement(selectElement) {
    const searchType = selectElement.dataset.searchType;
    if (!searchType) {
      return;
    }

    const searchUrls = {
      'cities': '/admin_panel/content_segmentations/search_cities'
    }

    const searchUrl = searchUrls[searchType];
    if (!searchUrl) {
      return;
    }

    const slim = new SlimSelect({
      select: selectElement,
      placeholder: 'Seleccione una opción',
      searchPlaceholder: 'Buscar...',
      searchText: 'Buscando...',
      allowDeselect: true,
      events: {
        search: async (query) => {
          if (query.length < 2) {
            return [];
          }

          try {
            const response = await fetch(`${searchUrl}?query=${encodeURIComponent(query)}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              credentials: 'same-origin'
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            const results = data.map(item => ({
              text: item.city,
              value: item.city
            }));

            return results;

          } catch (error) {
            return [];
          }
        }
      }
    });
  }

  delete(event) {
    event.preventDefault()

    const link = event.currentTarget
    const segmentId = link.dataset.segmentId
    const url = link.getAttribute('href')

    if (!confirm('¿Eliminar esta segmentación?')) {
      return
    }

    fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'
      }
    })
    .then(response => {
      if (response.ok) {
        const rows = document.querySelectorAll(`tr[data-segment-id='${segmentId}']`)
        rows.forEach(row => row.remove());
        link.remove();
      } else {
        console.error('No se pudo eliminar la segmentación')
      }
    })
    .catch(error => {
      console.error('Error:', error)
    })
  }
}
