import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="update-password-modal"
export default class extends Controller {
  static targets = ["password", "passwordConfirmation", "passwordError", "submit", "close", "form", "modalTitle1", "modalTitle2", "modalSubtitle1", "modalSubtitle2", "checkbox", "reloadModal"]

  connect() {
    this.updateSubmitButton();
    this.checkboxTarget.addEventListener('change', this.updateSubmitButton.bind(this));
    this.formTarget.addEventListener('submit', this.handleSubmit.bind(this));
    this.reloadModalTarget.addEventListener('click', this.reloadPage.bind(this));
    this.closeTarget.addEventListener('click', this.closeModal.bind(this));
  }

  validatePassword() {
    const passRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passRegex.test(this.passwordTarget.value) || this.passwordTarget.value !== this.passwordConfirmationTarget.value) {
      this.passwordErrorTarget.classList.remove('d-none');
      return false;
    } else {
      this.passwordErrorTarget.classList.add('d-none');
    }
    return true;
  }

  updateSubmitButton() {
    this.submitTarget.disabled = !this.checkboxTarget.checked;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validatePassword()) {
      this.passwordErrorTarget.style.display = 'none';

      this.modalTitle1Target.classList.add('d-none');
      this.modalTitle2Target.classList.remove('d-none');
      this.modalSubtitle1Target.classList.add('d-none');
      this.modalSubtitle2Target.classList.remove('d-none');
      this.formTarget.style.display = 'none';
      this.submitTarget.style.display = 'none';
      this.closeTarget.style.display = 'block';
      this.reloadModalTarget.style.display = 'block';
      this.formTarget.submit();
    }
  }

  reloadPage() {
    window.location.reload();
  }

  closeModal() {
    this.element.style.display = 'none';
    this.passwordTarget.value = '';
    this.passwordConfirmationTarget.value = '';
    setTimeout(function () {
      window.location.reload();
    }, 200)
  }
}
