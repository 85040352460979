import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="nform"
export default class extends Controller {

    static targets = ['links', 'template']

    add_association(event) {
        event.preventDefault();

        const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML('beforebegin', content)
    }

    add_answer_association(event) {
        event.preventDefault();

        const content = this.templateTarget.innerHTML.replace(/NEW_ANSWER/g, new Date().getTime())
        this.linksTarget.insertAdjacentHTML('beforebegin', content)
    }

    async removeAssociation(event) {
        event.preventDefault()
        const wrapper = event.target.closest('.nested-fields')
    
        if (wrapper && wrapper.dataset.newRecord === 'true') {
          wrapper.remove()
        } else {
          if (confirm('¿Seguro que quieres eliminar este elemento? Este elemento ya está guardado y podría afectar otras configuraciones.')) {
            if (wrapper) {
              wrapper.querySelector('input[name*="_destroy"]').value = 1
              wrapper.style.display = 'none'
            }
          }
        }
    }
}
