import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="admin--navbar"
export default class extends Controller {
  collapseSideMenuClicked(event) {
    event.preventDefault();
    let bodyTag = document.getElementsByTagName('body').item(0);
    bodyTag.classList.toggle("sidebar-mini");
  }

  toggleRightMenu(e) {
    e.preventDefault();
    let overlayId = "adminPanelOverlay";
    let elem = document.getElementById(overlayId);
    if (elem === null) {
      let div = document.createElement('div');
      div.id = overlayId;
      div.classList.add("close-layer", "visible");
      div.style.height = "2409px";
      this.element.appendChild(div);
    } else {
      elem.remove();
    }
  }
}
