import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="admin-panel--settings--users"
export default class extends Controller {

    static values = {
        url: String
    }

    async connect() {
        const url = this.urlValue;
        await get(url, {responseKind: "turbo-stream"});
    }
}
