import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password"
export default class extends Controller {
  static targets = [ "password", "toggle" ]

  showPassword() {
    if (this.passwordTarget.type === 'password') {
      this.passwordTarget.type = 'text';
      this.toggleTarget.classList.add("shown");
      this.toggleTarget.setAttribute('data-hidden', 'true');
    } else {
      this.passwordTarget.type = 'password';
      this.toggleTarget.classList.remove("shown");
      this.toggleTarget.setAttribute('data-hidden', 'false');
    }
  }
}
