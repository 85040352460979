import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="event-widget"
export default class extends Controller {
  toggle() {
    this.closeAllWidgets();
    this.element.classList.toggle("closed");
  }

  closeAllWidgets() {
    const widgets = document.querySelectorAll('.widget');
    widgets.forEach(widget => {
      if (widget !== this.element) {
        widget.classList.add('closed');
      }
    });
  }
}
