import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="admin--navigation"
export default class extends Controller {

    static targets = ['tab']

    connect() {
        this.currentIndex = 0;
        this.apply();
    }

    clickTab(event) {
        event.preventDefault();
        let currentActive = this.tabTargets[this.currentIndex];
        let nextIndex = this.tabTargets.indexOf(event.target);
        if (nextIndex === -1) {
            nextIndex = this.tabTargets.indexOf(event.target.parentElement);
        }
        currentActive.classList.remove('tab-active');
        let next = this.tabTargets[nextIndex];
        next.classList.add('tab-active')
        this.currentIndex = nextIndex;
        this.apply();
    }

    async apply() {
        const current = this.tabTargets[this.currentIndex];
        const href = current.href;
        await get(href, {responseKind: 'turbo-stream'});
    }

}
