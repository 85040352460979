import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="communities-legals-modal"
export default class extends Controller {
  static targets = [ "submitButton", "checkbox" ]

  connect() {
    this.submitButtonTarget.disabled = true
  }

  toggleButton() {
    this.submitButtonTarget.disabled = !this.checkboxTarget.checked
  }
}
