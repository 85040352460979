import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cell"];

  connect() {
    const today = new Date().getDate();
    const baseUrl = this.element.dataset.baseUrl || "/static/calendario_de_adviento#reto"; // Recogemos la url de la pagina en la que nos encontramos

    this.cellTargets.forEach((cell) => {
      const cellDay = parseInt(cell.dataset.day, 10);

      if (cellDay === today) {
        // Activamos el dia y pasamos la url que viene del partial de la modal
        cell.classList.add("calendar__cell--active");
        /*cell.addEventListener("click", () => {
          window.location.href = `${baseUrl}`;
        });*/
      } else if (cellDay < today) {
        cell.classList.add("calendar__cell--disabled");
      }
    });
    this.element.addEventListener("click", (event) => {
      event.stopPropagation(); // Prevenir propagación innecesaria
      window.location.href = `${baseUrl}`;
    });
  }
}
