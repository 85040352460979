import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['products'];

    async addProduct(event) {
        this.createInputProduct();
    }

    async removeProduct(event) {
        event.target.parentNode.parentNode.remove();
        this.totalUnits;
    }

    async calculatePointsByProduct(event) {
        const productId = event.params.product;
        const pointsGiven = event.params.points;
        const totalPointsByProduct = document.getElementById(`total_points_${productId}`);
        const units = event.target.value;
        if (units > 0) {
            totalPointsByProduct.value = units * pointsGiven;
        } else {
            totalPointsByProduct.value = 0;
            event.target.value = "";
        }
        this.totalUnits;
    }

    createInputProduct() {
        if (!document.getElementById(this.productId)) {
            const productRow = document.createElement('tr');
            productRow.id = this.productId;
            productRow.innerHTML = `<td>${this.productName}</td><td>${this.pointsGiven}</td>
            <td><input type="text" data-admin--validate-ticket-component-product-param="${this.productId}"
            data-admin--validate-ticket-component-points-param="${this.pointsGiven}"
            data-action="keyup->admin--validate-ticket-component#calculatePointsByProduct"></td>
            <td><input type="text" id="total_points_${this.productId}" readonly class="total-points" value="0"></td>
            <td><i class="bi bi-trash" data-action="click->admin--validate-ticket-component#removeProduct"></i></td>`;
            document.getElementById('product_dynamic_input_container').appendChild(productRow);
        }
    }

    get productId() {
        return this.productsTarget.selectedOptions[0].value
    }

    get productName() {
        return this.productsTarget.selectedOptions[0].text
    }

    get pointsGiven() {
        return this.productsTarget.selectedOptions[0].getAttribute('data-points-given')
    }

    get totalUnits() {
        let totalUnits = 0;
        document.querySelectorAll('.total-points').forEach(function(input) {
            totalUnits += parseInt(input.value) || 0;
        });
        document.getElementById('validation_units').value = totalUnits;
    } 
}