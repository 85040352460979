export function add_stylesheet(stylesheet) {
    if (document.querySelectorAll(`link[rel=stylesheet][href~="${stylesheet}"]`).length < 1) {
        const head = document.head
        const link = document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.href = stylesheet
        head.appendChild(link)
    }
}


export function removeStylesheet(stylesheet) {
    const stylesheets = document
        .querySelectorAll(`link[rel=stylesheet][href~="${stylesheet}"]`)
    stylesheets.forEach((e) => e.parentNode.removeChild(e))
}