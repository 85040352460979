import {Controller} from "@hotwired/stimulus";
import {Modal} from "bootstrap";

export default class extends Controller {

    static values = {
        visible: Boolean
    }

    connect() {
        this.modal = new Modal(this.element)
        if (this.visibleValue) {
            this.modal.show();
        }
    }

    show(event) {
        event.preventDefault()
        this.modal.show()
    }
}
