import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="communities-subscribe-modal"
export default class extends Controller {
  connect() {
    const options = document.querySelectorAll('.modal__option');
    const modal = document.getElementById('communities-subscribe-modal');
    const closeButton = document.getElementById('close-modal');

    options.forEach(option => {
      option.addEventListener('click', event => {
        const community = event.currentTarget.dataset.community;
        console.log(community);
        modal.classList.add('open');
        const spanElement = modal.querySelector('#community-id');
        spanElement.textContent = `${community}`;
      });
    });

    closeButton.addEventListener('click', () => {
      modal.classList.remove('open');
    });
  }
}