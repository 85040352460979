import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="vademecum-details"
export default class extends Controller {
  static targets = [ "option", "carouselItem", "carousel" ]

  connect() {
    this.activeSlideIndex = 0;
    this.carouselInstance = new bootstrap.Carousel(this.carouselTarget, {
      interval: false
    });
  }

  handleOptionClick(event) {
    this.optionTargets.forEach(option => {
      option.classList.remove('active');
    });
    event.currentTarget.classList.add('active');

    const slideIndex = this.optionTargets.indexOf(event.currentTarget);

    if (this.activeSlideIndex !== slideIndex) {
      this.carouselInstance.to(slideIndex);
      this.activeSlideIndex = slideIndex;
    }
  }
}
