import {Controller} from "@hotwired/stimulus";
import {Toast} from "bootstrap";


export default class extends Controller {

    connect() {
        this.toast = new Toast(this.element, {
            animation: false,
            autohide: false
        });
        this.toast.show();
        setTimeout(() => {
            this.hide()
        }, 10000)
    }

    close(event) {
        event.preventDefault();
        this.hide()
    }

    hide() {
        this.element.classList.add('animate__lightSpeedOutRight')
        this.element.addEventListener('animationend', () => {
            this.toast.hide()
            this.element.remove()
        })
    }

}
