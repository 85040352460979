import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

// Connects to data-controller="admin-panel--select-manager"
export default class extends Controller {
  static targets = ['manager', 'company']

  connect() {
    this.setSelected = (slim, optsArray) => {
      const newOpts = optsArray.filter((opt) => !slim.getData().some((slimOpt) => opt.value === slimOpt.value));
      if (newOpts.length > 0) {
        const newData = slim.getData();
        newOpts.forEach((opt) => newData.push(opt));
        slim.setData(newData.sort((l, r) => l.value - r.value));
      }

      slim.setSelected(optsArray.map((opt) => opt.value));
    }
  }

  managerTargetConnected(manager) {
    const addUpdateCompanyEvent = async (manager) => {
      while (!manager.slim) { // There is a chance Slim is not initialized yet before connecting
        await setTimeout(500);
      }

      // Unless we destroy and regenerate the SlimSelect, afterChange event will not work (it is only read on init),
      // so we copy all known configuration options manually here.
      // If `slim` Stimulus controller is updated, we should also update this
      const newData = manager.slim.getData().map((opt) => { return {
        text: opt.text,
        value: opt.value,
        selected: opt.selected
      }});
      const newSettings = {
        closeOnSelect: manager.slim.settings.closeOnSelect,
        showSearch: manager.slim.settings.showSearch,
        allowDeselect: manager.slim.settings.allowDeselect
      };
      const newEvents = {
        search: manager.slim.events.search,
        afterChange: (newVals) => {
          const newValIds = newVals.map((opt) => opt.value);
          const modelsData = manager.modelsData?.filter((model) => newValIds.includes(model.id) && model.company_id);
          if (modelsData?.length > 0) {
            this.setSelected(this.companyTarget.slim, modelsData.map((model) => { return {
              text: model.company_name,
              value: model.company_id
            }}));
          }
        }
      }
      manager.slim.destroy();

      new SlimSelect({
        select: manager,
        data: newData,
        settings: newSettings,
        events: newEvents
      });
    };

    addUpdateCompanyEvent(manager)
  }
}
