import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sticky-navbar"
// Add the sticky__container class to the first element below the sticky navbar
export default class extends Controller {
  connect() {
    this.updateStickyOffset();
    this.firstBelowContainer = document.querySelector('.sticky__container');
    window.addEventListener('scroll', this.stickOnScroll.bind(this));
    window.addEventListener('resize', this.adjustTopProperty.bind(this));
  }

  disconnect() {
    window.removeEventListener('scroll', this.stickOnScroll.bind(this));
    window.removeEventListener('resize', this.adjustTopProperty.bind(this));
  }

  updateStickyOffset() {
    this.sticky = this.element.offsetHeight;
    this.stickyTop = this.element.offsetTop;
  }

  stickOnScroll() {
    if (window.innerWidth > 600) {
      if (window.scrollY >= (this.stickyTop)) {
        this.element.classList.add("sticky");
        this.firstBelowContainer.style.paddingTop = `calc(var(--gap-xl) + ${this.sticky}px)`;
      } else {
        this.element.classList.remove("sticky");
        this.firstBelowContainer.style.paddingTop = 'var(--gap-xl)';
      }
    } else {
      this.element.classList.remove("sticky");
      this.firstBelowContainer.style.paddingTop = 'var(--gap-xl)';
    }
  }

  adjustTopProperty() {
    this.element.classList.remove("sticky");
    this.firstBelowContainer.style.paddingTop = '0px';
    this.updateStickyOffset();
    this.stickyTop = this.element.getBoundingClientRect().top + window.scrollY;
    this.stickOnScroll();
  }
}
