import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="asesor-widget"
export default class extends Controller {
  static targets = ["widget", "toggle"];

  connect() {
    this.toggleTarget.addEventListener("click", this.toggleWidget.bind(this));

    const misDatosElement = document.getElementById("mis-datos-anchor");
    if (misDatosElement) {
      const misDatosRect = misDatosElement.getBoundingClientRect();
      const misDatosMiddle = (misDatosRect.top + misDatosRect.bottom) / 2;
      this.widgetTarget.style.top = `${misDatosMiddle}px`;
    }
  }

  toggleWidget() {
    this.widgetTarget.classList.toggle("closed");
  }
}
