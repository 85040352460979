import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--toggle-visibility"
export default class extends Controller {
  static values = {
    defaultVisibility: String,
    elementIds: String
  }

  connect() {
    if (this.defaultVisibilityValue !== 'hidden' && this.element.checked) {
      this.hideOrShow('hide')
    }
  }

  toggleVisibility() {
    for (const elementId of this.elementIdsValue.split(',')) {
      const element = document.getElementById(elementId)
      element.classList.toggle('d-none')
    }
  }

  hideOrShow(action) {
    for (const elementId of this.elementIdsValue.split(',')) {
      const element = document.getElementById(elementId)
      if (action === 'hide') {
        element.classList.add('d-none')
      } else {
        element.classList.remove('d-none')
      }
    }
  }
}
