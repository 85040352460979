import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

export default class extends Controller {
  static values = { url: String }
  static targets = ['search', 'pageSize', 'filter0', 'filter1']

  async submit(e) {
    e.preventDefault();

    const queryParams = {
      page_size: this.pageSizeTarget.value,
      search: this.searchTarget.value
    }

    if (this.hasFilter0Target) {
      queryParams['filter_0'] = this.filter0Target.value
    }

    if (this.hasFilter1Target) {
      queryParams['filter_1'] = this.filter1Target.value
    }

    await get(this.urlValue, {
      query: queryParams,
      responseKind: 'turbo-stream'
    });
  }
}
