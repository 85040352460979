import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-closed-for-one-hour"
export default class extends Controller {
  connect() {
    this.checkModal();
  }

  checkModal() {
    const lastClosed = localStorage.getItem('modalLastClosedForOneHour');
    if (!lastClosed || new Date().getTime() - lastClosed >= 3600000) {
      this.element.classList.add('open');
    }
  }

  close() {
    this.element.classList.remove('open');
    localStorage.setItem('modalLastClosedForOneHour', new Date().getTime());
  }
}