import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

export default class extends Controller {

    static values = {
        url: String
    }

    async click(event) {
        event.preventDefault()
        await get(this.urlValue, {responseKind: 'turbo-stream'})
    }

}
