import {Controller} from "@hotwired/stimulus"
import {post} from "@rails/request.js";

function searchUserId(userId) {
  return function (user) {
    return user.user_id === userId
  }
}

// Connects to data-controller="sales"
export default class extends Controller {

  static values = {
    challengeId: Number,
    rate: Number,
  }

  connect() {
    this.payload = { sales: [] };
    this.setupInputListeners();
  }

  setupInputListeners() {
    this.element.addEventListener('change', (event) => {
      if (event.target.matches('input[type="number"]')) {
        this.enableButton();
      }
    });
  }

  enableButton() {
    const inputs = document.querySelectorAll('input[type="number"]');
    const salesBtn = document.querySelector('#sales-btn');
    const isAnyInputFilled = Array.from(inputs).some(input => input.value > 0);
    salesBtn.disabled = !isAnyInputFilled;
  }

  distributeSale(event) {
    const newValue = parseInt(event.target.value);
    const spanId = event.target.name;
    const earnsSpan = document.getElementById(spanId);
    if (earnsSpan !== undefined) {
      if (isNaN(newValue)) {
        earnsSpan.innerHTML = "";
      } else {
        earnsSpan.innerHTML = (newValue * this.rateValue).toString();
      }
    }

    const userId = parseInt(event.target.dataset.userid)
    const userSplit = this.payload.sales.filter(searchUserId(userId))[0]
    if (newValue > 0) {
      if (userSplit !== undefined) {
        userSplit.units = newValue;
      } else {
        this.payload.sales.push({
          user_id: userId,
          units: newValue
        });
      }
    } else {
      if (userSplit !== undefined) {
        this.payload.sales = this.payload.sales.filter(s => s.user_id !== userSplit.user_id)
      }
    }
  }

  confirmSale(event) {
    event.preventDefault();

    post(`/challenges/${this.challengeIdValue}/split_sales`, {
      body: this.payload
    }).then(response => {
      if (response.ok) {
        window.location.reload();
      }
    }).catch(error => {
      console.log(error);
    })
  }
}
