import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="home"
export default class extends Controller {

    static values = {
        trainingsUrl: String
    }

    async connect() {
        await this.searchRootTrainings()
    }

    async searchRootTrainings() {
        await get(this.trainingsUrlValue, {responseKind: 'turbo-stream'})
    }

    openNotification(event) {
        const notificationId = event.currentTarget.dataset.notificationId;
    
        fetch(`/notifications/${notificationId}/visualizations`, {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
            },
            body: JSON.stringify({}),
        });
    }
}
