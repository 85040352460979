import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="fixed-trainings-search"
export default class extends Controller {
  connect() {
    this.footer = document.querySelector('footer')
    this.scrollFunction = this.scrollFunction.bind(this)
    window.addEventListener('scroll', this.scrollFunction)
  }
  disconnect() {
    window.removeEventListener('scroll', this.scrollFunction)
  }
  scrollFunction() {
    if (!this.isElementVisible(this.footer)) {
      this.element.classList.add('shown')
    } else {
      this.element.classList.remove('shown')
    }
  }
  isElementVisible(element) {
    const rect = element.getBoundingClientRect()
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
    return (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0)
  }
}
