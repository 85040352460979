import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="certificates-table"
export default class extends Controller {
  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const table = document.querySelector("#certificates-table");

    if (table) {
      new DataTable(table, {
        language: {
          search: "",
          searchPlaceholder: "Buscar certificados...",
          lengthMenu: "_MENU_ certificados por página",
          zeroRecords: "No hay certificados con estos criterios de búsqueda.",
          info: "",
          infoEmpty: "Aún no has conseguido ningún certificado.",
          infoFiltered: "(filtrado de un total de _MAX_ registros)",
          paginate: {
            first: "Primero",
            last: "Último",
            next: ">",
            previous: "<",
          },
        },
        bLengthChange: false,
        iDisplayLength: 5,
      });
    }
  }
}