import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="set-training-breadcrumb"
export default class extends Controller {
  static targets = ["name", "exam"]

  connect() {
    if (this.hasNameTarget) {
      const trainingBreadcrumb = document.getElementById('training-breadcrumb-id');
      const challengeBreadcrumb = document.getElementById('challenge-breadcrumb-id');
      if (trainingBreadcrumb) {
        trainingBreadcrumb.textContent = this.nameTarget.textContent;
      }
      if (challengeBreadcrumb) {
        challengeBreadcrumb.textContent = this.nameTarget.textContent;
      }
    }
  }
}
