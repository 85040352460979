import {Controller} from "@hotwired/stimulus"
import {get, post} from "@rails/request.js";

// Connects to data-controller="tsearcher"
export default class extends Controller {
  static values = { title: String }
  static targets = ["content", "loader", "checkbox", "search"]

  async connect() {
    this.search = "";
    this.typesFilter = [];
    this.isFavorite = false;
    this.completed = null;
    await this.loadContent();
    this.hideLoader();
  }

  async loadContent() {
    let urlParams = "";

    if (this.completed !== null) {
      urlParams = new URLSearchParams({
        training_types: this.typesFilter.join(','),
        favorite: this.isFavorite,
        search: this.search,
        completed: this.completed
      }).toString();
    } else {
      urlParams = new URLSearchParams({
        training_types: this.typesFilter.join(','),
        favorite: this.isFavorite,
        search: this.search
      }).toString();
    }

    const response = await get(`/trainings/search?${urlParams}`, {
      responseKind: 'json'
    });

    if (response.statusCode === 200) {
      const data = await response.json;
      const cards = [];
      data.forEach(d => {
        cards.push(this.buildCard(d));
      });

      this.contentTarget.innerHTML = cards.join('\n')
    }
  }

  hideLoader() {
    this.loaderTarget.style.display = 'none'
    this.contentTarget.style.display = ''
  }

  showLoader() {
    this.loaderTarget.style.display = 'flex'
    this.contentTarget.style.display = 'none'
  }

  async onChangeCompleted(event) {
    if (event.target.checked)
      this.completed = true;
    else
      this.completed = null;
    this.showLoader();
    await this.loadContent();
    this.hideLoader();
  }

  async onChangeNotCompleted(event) {
    if (event.target.checked)
      this.completed = false;
    else
      this.completed = null;
    this.showLoader();
    await this.loadContent();
    this.hideLoader();
  }

  async onChange(event) {
    const tag = event.target.dataset.name;
    if (tag === "fav") {
      this.isFavorite = event.target.checked;
    } else {
      if (this.typesFilter.includes(tag)) {
        this.typesFilter = this.typesFilter.filter(t => t !== tag);
      } else {
        this.typesFilter.push(tag);
      }
    }

    this.showLoader();
    await this.loadContent();
    this.hideLoader();
  }

  async clear() {
    this.search = "";
    this.typesFilter = [];
    this.isFavorite = false;
    this.completed = null;

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    });

    this.searchTarget.value = "";

    this.showLoader();
    await this.loadContent();
    this.hideLoader();
  }

  async switch(event) {
    event.preventDefault();
    event.target.classList.toggle("fav");
    await post(`/trainings/${event.target.dataset.trainingid}/favorite`);
  }

  async onSearchInputChange(event) {
    event.preventDefault();
    this.search = event.target.value;

    this.showLoader();
    await this.loadContent();
    this.hideLoader();
  }

  buildCard(trainingData) {
    const rewardHtml = (trainingData.reward !== 0 && trainingData.reward !== null && trainingData.reward !== '')
      ? `<h4 class="page__card--reward">${trainingData.reward} ${this.titleValue}</h4>`
      : '';

    return `
      <a href="/trainings/${trainingData.id}" class="page__card">
        <div class="page__card--img">
          <div class="page__card--img-clip ${trainingData.viewed_and_finished ? 'page__card--done' : ''}">
            <img alt="training-image" src="${trainingData.image_url}"/>
            <img alt="training-image" class="page__card--img-bg" src="${trainingData.image_url}"/>
            <div class="page__card--info">
              <button
                class="page__card--heart ${trainingData.is_favorite ? "fav" : ""}" data-trainingid="${trainingData.id}"
                data-trainingid="${trainingData.id}"
                data-action="click->tsearcher#switch"
              >
              </button>
              ${rewardHtml}
            </div>
          </div>
        </div>
        <div class="page__card--title">
          <h4> ${trainingData.name} </h4>
        </div>
      </a>
    `;
  }

}
