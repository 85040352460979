import { Controller } from "@hotwired/stimulus"
import {post} from "@rails/request.js";

// Connects to data-controller="authorize-challenge"
export default class extends Controller {
  static values = {
    challengeId: Number,
    authorizingMessage: String,
    authorizedMessage: String,
    toastSuccessMessage: String,
    toastErrorMessage: String
  }

  static targets = ["checkbox", "button"]

  reloadPage() {
    setTimeout(() => {
      location.reload();
    }, 2000);
  }

  authorizeChallenge(event) {
    if (this.checkboxTarget.checked) {
      this.buttonTarget.innerHTML = this.authorizingMessageValue;
      post(`/challenges/${this.challengeIdValue}/join`, {
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        if (response.ok) {
          this.showToast('success');
          this.reloadPage();
          this.buttonTarget.innerHTML = this.authorizedMessageValue;
        } else {
          console.error('Error:', error);
        }
      }).catch(error => {
        console.error('Error:', error);
        this.reloadPage()
      });
    } else {
      this.showToast('error');
    }
  }

  showToast(string) {
    const toast = document.getElementById('toast');
    if (string === 'success') {
      toast.innerHTML = `<p class="success">${this.toastSuccessMessageValue}</p>`
    } else {
      toast.innerHTML = `<p class="error">${this.toastErrorMessageValue}</p>`;
    }
    toast.classList.add("show");

    setTimeout(() => {
      toast.classList.remove("show")
    }, 2000);
  }
}
