import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="forbidden-modal"
export default class extends Controller {
  connect() {
    this.element.classList.remove('open');
    let agent = navigator.userAgent;
    if (agent.includes('lor005_mobile_app')) {
      this.element.classList.add('open');
    }
  }
}
