import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name", "surname", "nif", "phone", "mobile", "email", "emailConfirmation", "password", "passwordConfirmation", "acceptConditions"]

  connect() {
  }

  validateForm() {
    let errors = 0;
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    let dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

    this.constructor.targets.forEach(targetName => {
      let target = this[`${targetName}Target`];
      let inputElement = target.querySelector('input');
      let errorElement = target.querySelector('.error');

      if (inputElement && inputElement.value === "") {
        if (errorElement) {
          errorElement.classList.remove("d-none");
        }
        errors++;
      } else {
        if (errorElement) {
          errorElement.classList.add("d-none");
        }

        // Email
        if (targetName === 'email' && !emailRegex.test(inputElement.value)) {
          if (errorElement) {
            errorElement.classList.remove("d-none");
            errors++;
          }
        }
        if (targetName === 'emailConfirmation' && inputElement.value !== this.emailTarget.querySelector('input').value) {
          if (errorElement) {
            errorElement.classList.remove("d-none");
            errors++;
          }
        }

        // Password
        if (targetName === 'password' && !passwordRegex.test(inputElement.value)) {
          if (errorElement) {
            errorElement.classList.remove("d-none");
            errors++;
          }
        }
        if (targetName === 'passwordConfirmation' && inputElement.value !== this.passwordTarget.querySelector('input').value) {
          if (errorElement) {
            errorElement.classList.remove("d-none");
            errors++;
          }
        }
      }
    });

     // Accept conditions
     if (this.hasAcceptConditionsTarget && !this.acceptConditionsTarget.querySelector('#user_accepts_conditions').checked) {
      this.acceptConditionsTarget.querySelector('.error').classList.remove("d-none");
      errors++;
    }

    if (!errors) {
      document.getElementById('register_form').submit();
    }
  }

  submitForm(event) {
    event.preventDefault();
    this.validateForm();
  }
}
