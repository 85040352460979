import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-plugin"
export default class extends Controller {
  static values = {
    fileUrl: String,
    fileName: {
      type: String,
      default: 'image.jpg'
    }
  }
  static targets = ["trainingModal"]

  showTrainingModal() {
    this.trainingModalTarget.classList.remove('d-none');
  }

  closeTrainingModal() {
    this.trainingModalTarget.classList.add('d-none');
  }

  handleDownload() {
    const link = document.createElement('a');
    link.href = this.fileUrlValue;
    link.download = this.fileNameValue;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
