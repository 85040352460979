import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';
import { get } from '@rails/request.js'

// Connects to data-controller="admin-panel--select-roles-categories"
export default class extends Controller {
  static targets = ['roles', 'categoriesES', 'categoriesPT']
  static values = { searchUrl: String }

  rolesTargetConnected(roles) {
    const addUpdateCategoriesEvent = async (roles) => {
      while (!roles.slim) { // There is a chance Slim is not initialized yet before connecting
        await setTimeout(500);
      }
      // Unless we destroy and regenerate the SlimSelect, afterChange event will not work (it is only read on init),
      // so we copy all known configuration options manually here.
      // If `slim` Stimulus controller is updated, we should also update this
      const newData = roles.slim.getData().map((opt) => { return {
        text: opt.text,
        value: opt.value,
        selected: opt.selected
      }});
      const newSettings = {
        closeOnSelect: roles.slim.settings.closeOnSelect,
        showSearch: roles.slim.settings.showSearch,
        allowDeselect: roles.slim.settings.allowDeselect
      };
      const newEvents = {
        search: roles.slim.events.search,
        afterChange: (newVals) => {
          const rolesIds = newVals.map((opt) => opt.value);
          if (rolesIds.length > 0) {
            get(this.searchUrlValue, { query: { roles_ids: rolesIds }, responseKind: 'json' })
              .then((response) => response.json) // Obtain json
              .then((data) => {
                if (this.hasCategoriesESTarget) {
                  this.categoriesESTarget.slim.setData(data.es);
                }
                if (this.hasCategoriesPTTarget) {
                  this.categoriesPTTarget.slim.setData(data.pt);
                }
              });
          }
        }
      }
      roles.slim.destroy();

      new SlimSelect({
        select: roles,
        data: newData,
        settings: newSettings,
        events: newEvents
      });
    };

    addUpdateCategoriesEvent(roles)
  }
}
