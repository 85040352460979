import {Controller} from "@hotwired/stimulus";
import {add_stylesheet, removeStylesheet} from "../utils/index.js";

export default class extends Controller {

    static values = {
        styles: String
    }

    connect() {
        // Add the stylesheet of this component
        add_stylesheet(this.stylesValue)
   }

    disconnect() {
        removeStylesheet(this.stylesValue)
        super.disconnect();
    }
}
